












import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import { Mileage, MileageType } from "@/services/SpaceObjectsService"
import { mileageNumberFormatter } from "@/utilities/Formatter"
export default defineComponent({
    name: "SpaceObjectMileage",
    props: {
        mileage: Object as () => Mileage,
    },
    setup() {
        const state = reactive({})
        return {
            ...toRefs(state),
            MileageType,
            mileageNumberFormatter,
        }
    },
})
