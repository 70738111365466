














































































































import {
    computed,
    defineComponent,
    reactive,
    toRefs,
    watch,
} from "@vue/composition-api"
import SpaceObjectLocation from "@/components/SpaceObjectLocation.vue"
import SpaceObjectCoordinate from "./SpaceObjectCoordinate.vue"
import KeywordInput from "@/components/KeywordInput.vue"
import PlacePagingSelect from "@/components/PlacePagingSelect.vue"
import SpaceObjectMileage from "./SpaceObjectMileage.vue"
import MileageFilterInput from "@/components/MileageFilterInput.vue"
import CustomPropertiesViewer from "./CustomPropertiesViewer.vue"
import {
    page,
    itemsPerPage,
    keyword,
    buildStringFilter,
    buildNumberFilter,
    buildNumberRangeFilter,
} from "@/services/QueryParmsService"
import {
    getSpaceObjects,
    SpaceObjectDTO,
    SpaceObjectSortMethod,
    spaceObjectTypeFormatter,
    saveSpaceObjects,
    SpaceObjectType,
    putSpaceObjectDisabled,
} from "@/services/SpaceObjectsService"
import { currentRoute } from "@/services/Router"
import {
    ButtonResult,
    DialogButtonType,
    errorDialog,
    successDialog,
} from "@/services/DialogService"
import { hasPermission } from "@/services/AuthService"

export default defineComponent({
    name: "SpaceObject",
    components: {
        KeywordInput,
        SpaceObjectLocation,
        PlacePagingSelect,
        SpaceObjectMileage,
        SpaceObjectCoordinate,
        MileageFilterInput,
        CreateDialog: () => import("./CreateDialog.vue"),
        CustomPropertiesViewer,
    },
    props: {
        type: String as () => SpaceObjectType,
    },
    setup(props) {
        const state = reactive({
            items: [] as SpaceObjectDTO[],
            total: 0,
            loading: false,
        })

        const sortMethod = buildNumberFilter(
            "s",
            SpaceObjectSortMethod.NameWithDepthFirst
        )

        const ancestorIdFilter = buildStringFilter("ancestorId")

        const mileageFilter = buildNumberRangeFilter("mileage")

        function clickLocation(id: string) {
            ancestorIdFilter.value = id
        }

        const sortOptions = [
            {
                text: "名稱",
                value: SpaceObjectSortMethod.Name,
            },
            {
                text: "編號",
                value: SpaceObjectSortMethod.Number,
            },
            {
                text: "同所在位置後名稱",
                value: SpaceObjectSortMethod.NameWithDepthFirst,
            },
            {
                text: "同所在位置後編號",
                value: SpaceObjectSortMethod.NumberWithDepthFirst,
            },
            {
                text: "同層後名稱",
                value: SpaceObjectSortMethod.NameWithBreadthFirst,
            },
            {
                text: "同層後編號",
                value: SpaceObjectSortMethod.NumberWithBreadthFirst,
            },
        ]

        function searchSpaceObjects(pagination = true) {
            return getSpaceObjects({
                skip: pagination
                    ? itemsPerPage.value * (page.value - 1)
                    : undefined,
                take: pagination ? itemsPerPage.value : undefined,
                keyword: keyword.value,
                types: props.type ? [props.type] : undefined,
                sort: sortMethod.value!,
                ancestorIds: ancestorIdFilter.value
                    ? [ancestorIdFilter.value]
                    : undefined,
                mileageStart: mileageFilter.value.start,
                mileageEnd: mileageFilter.value.end,
                notDisabled: true,
            })
        }

        async function loadData() {
            state.loading = true
            const { total, items } = await searchSpaceObjects()
            state.items = items
            state.total = total
            state.loading = false
        }

        watch(
            () => currentRoute.value!.query,
            () => {
                loadData()
            }
        )

        loadData()

        async function exportSpaceObject() {
            const { items } = await searchSpaceObjects(false)
            saveSpaceObjects(items)
        }

        const headers = [
            {
                text: "ID",
                sortable: false,
                width: "4%",
            },
            {
                text: "所在位置",
                sortable: false,
                width: "15%",
            },
            {
                text: "里程",
                sortable: false,
                width: "6%",
            },
            {
                text: "編號",
                sortable: false,
                width: "10%",
            },
            {
                text: "名稱",
                sortable: false,
                width: "10%",
            },
            {
                text: "分類",
                sortable: false,
                width: "15%",
            },
            {
                text: "座標",
                width: "4%",
                sortable: false,
            },
            {
                text: "動作",
                width: "10%",
                sortable: false,
            },
        ]

        const importorPath = computed(() =>
            props.type === "SPACE" ? "/spaces/importer" : "/equips/importer"
        )

        const removerPath = computed(() =>
            props.type === "SPACE" ? "/spaces/remover" : "/equips/remover"
        )

        const detailBasePath = computed(() =>
            props.type === "SPACE" ? "/spaces/" : "/equips/"
        )

        const creatorPath = computed(() =>
            props.type === "SPACE" ? "/spaces/creator" : "/equips/creator"
        )

        async function deletePlace(input: SpaceObjectDTO) {
            const { button: confirmDelete } = await errorDialog(
                `確認要註銷此地點?`,
                "",
                DialogButtonType.ConfirmCancel
            )
            if (confirmDelete === ButtonResult.Cancel) return
            try {
                await putSpaceObjectDisabled(input.id)
                successDialog("成功註銷")
                loadData()
            } catch (error) {
                console.error(error)
                errorDialog("註銷失敗，請聯絡系統維護人員")
            }
        }

        const canEdit = hasPermission("SPACE_OBJECT_MODIFY")

        return {
            ...toRefs(state),
            headers,
            page,
            itemsPerPage,
            sortMethod,
            sortOptions,
            loadData,
            spaceObjectTypeFormatter,
            exportSpaceObject,
            clickLocation,
            ancestorIdFilter,
            mileageFilter,
            importorPath,
            removerPath,
            detailBasePath,
            creatorPath,
            deletePlace,
            canEdit,
        }
    },
})
