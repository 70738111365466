




















































import { NumberRange } from "@/services/QueryParmsService"
import { mileageNumberFormatter } from "@/utilities/Formatter"
import {
    computed,
    defineComponent,
    reactive,
    ref,
    toRefs,
} from "@vue/composition-api"

export default defineComponent({
    name: "MileageFilterInput",
    props: {
        errorMessages: [String, Array],
        label: { type: String, default: "" },
        value: {
            type: Object as () => NumberRange,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const form = ref(null as null | any)

        const state = reactive({
            menu: false,
            start: props.value?.start ?? (null as number | null | ""),
            end: props.value?.end ?? (null as number | null | ""),
        })

        function startRule(s: string | null) {
            if (s === "" || s === null) return true
            const v = parseFloat(s)
            if (isNaN(v)) return "必須是數值"
            if (state.end !== null && state.end !== "" && v > state.end)
                return "必須小於結束里程"
            return true
        }

        function endRule(s: string) {
            if (s === "" || s === null) return true
            const v = parseFloat(s)
            if (isNaN(v)) return "必須是數值"
            if (state.start !== null && state.start !== "" && v < state.start)
                return "必須大於起始里程"
            return true
        }

        const text = computed(() => {
            if (!props.value) return "--"
            const start = mileageNumberFormatter(props.value.start)
            const end = mileageNumberFormatter(props.value.end)
            return `${start} ~ ${end}`
        })

        function clear() {
            state.start = null
            state.end = null
            emit("input", <NumberRange>{
                start: state.start,
                end: state.end,
            })
            state.menu = false
        }

        function showPicker() {
            state.menu = true
        }

        function confirm() {
            if (!form.value!.validate()) return
            emit("input", <NumberRange>{
                start: state.start,
                end: state.end,
            })
            state.menu = false
        }

        return {
            form,
            ...toRefs(state),
            blur,
            clear,
            showPicker,
            text,
            startRule,
            endRule,
            confirm,
        }
    },
})
