











import { Coordinate } from "@/services/SpaceObjectsService"
import { computed, defineComponent } from "@vue/composition-api"
export default defineComponent({
    name: "SpaceObjectCoordinate",
    props: {
        coordinate: Object as () => Coordinate,
    },
    setup(props) {
        const href = computed(() =>
            props.coordinate
                ? `https://www.google.com.tw/maps/place/${props.coordinate.x}°N+${props.coordinate.y}°E`
                : undefined
        )
        return {
            href,
        }
    },
})
