













import {
    getSpaceObjects,
    SpaceObjectSortMethod,
} from "@/services/SpaceObjectsService"
import { defineComponent } from "@vue/composition-api"
import PageingSelect from "./PagingSelect.vue"
export default defineComponent({
    name: "PlacePagingSelect",
    components: {
        PageingSelect,
    },
    props: {
        value: {
            type: [Array as () => string[], String],
            default: null,
        },
        label: String,
        multiple: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        clearable: Boolean,
        exceptAncestorIds: {
            type: Array as () => string[],
        },
    },
    setup(props, { emit }) {
        async function spaceObjectIdSearch(ids: string[]) {
            const { items } = await getSpaceObjects({
                ids,
            })
            return items.map((item) => ({
                value: item.id,
                text: `${item.name} (${item.number})`,
            }))
        }

        async function spaceObjectKeywordSearch(
            keyword: string,
            skip: number,
            take: number
        ) {
            const { total, items } = await getSpaceObjects({
                keyword,
                skip,
                take,
                exceptAncestorIds: props.exceptAncestorIds,
                sort: SpaceObjectSortMethod.NameWithDepthFirst,
                notDisabled: true,
            })
            return {
                total,
                items: items.map((item) => ({
                    value: item.id,
                    text: `${item.spaceObjectPath.namePath.join("/ ")} (${
                        item.number
                    })`,
                })),
            }
        }

        function valueInput(value: string[] | string) {
            emit("input", value)
        }

        return {
            spaceObjectIdSearch,
            spaceObjectKeywordSearch,
            valueInput,
        }
    },
})
