
























import { CustomProperty } from "@/services/SpaceObjectsService"
import { defineComponent, reactive, toRefs } from "@vue/composition-api"
export default defineComponent({
    name: "CustomPropertiesViewer",
    props: {
        value: {
            type: Array as () => CustomProperty[],
            required: true,
        },
    },
    setup() {
        const state = reactive({
            dialog: false,
        })

        return {
            ...toRefs(state),
        }
    },
})
